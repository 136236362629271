import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerContentProps,
  Box,
} from '@chakra-ui/react';
import { useGenericDrawerStore } from '_store';

export interface GenericDrawerProps {
  data: {
    title: string;
    content: string | React.ReactNode;
  };
}

const GenericDrawer: React.FC<Partial<DrawerContentProps>> = (props) => {
  const { isOpen, data, close } = useGenericDrawerStore();

  return (
    <Drawer
      placement="left"
      size="xl"
      isOpen={isOpen}
      onClose={close}
      {...props}>
      <Box position="relative" zIndex={99999999}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontSize="1.5rem">{data?.title}</DrawerHeader>
          {data?.action ? (
            <Box px="1rem" w="100%" my="1rem">
              {data?.action}
            </Box>
          ) : null}
          <DrawerBody overflowY="scroll">
            {isOpen && data?.content ? data?.content : null}
          </DrawerBody>
          <DrawerFooter />
        </DrawerContent>
      </Box>
    </Drawer>
  );
};

export default GenericDrawer;
