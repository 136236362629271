import { Text, Box, Link } from '@chakra-ui/react';
import HexagonButton from '_components/HexagonButton';

interface HardSellingSultanProps {
  userLevel: number;
}

const HardSellingSultan: React.FC<HardSellingSultanProps> = ({ userLevel }) => {
  return (
    <Box fontSize="1rem">
      {userLevel >= 2 ? (
        <Text fontWeight="bold">
          You will be able to rewatch the events during August 22 - September 4,
          2021
        </Text>
      ) : (
        <>
          <Text fontWeight="bold">
            <Text as="span" fontWeight="bold" color="bluesky.500">
              Sultan
            </Text>{' '}
            tier will be able to rewatch the events during August 22 - September
            4, 2021.
          </Text>
          <Box mt="1rem" mb="2rem" display="flex" justifyContent="center">
            <Link href="/ticket" isExternal>
              <HexagonButton>Buy Sultan Ticket</HexagonButton>
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
};

export default HardSellingSultan;
