export const parseIdFromToken = (token: string) => {
  try {
    const base64Str = token.split(/\./)[1];
    const buff = Buffer.from(base64Str, 'base64');
    const jsonstr = buff.toString('utf-8');
    return JSON.parse(jsonstr).id;
  } catch (error) {
    return null;
  }
};
