import create from 'zustand';

interface ILoginModalStore {
  isOpen: boolean;
  message: null | string;
  open: () => void;
  close: () => void;
  setMessage: (message: string) => void;
  clearMessage: () => void;
}

export const useLoginModalStore = create<ILoginModalStore>((set) => ({
  isOpen: false,
  message: null,
  open: () => set(() => ({ isOpen: true })),
  close: () => set(() => ({ isOpen: false, message: '' })),
  setMessage: (message: string) => set(() => ({ message })),
  clearMessage: () => set(() => ({ message: '' })),
}));
