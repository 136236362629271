import PlanetFragment from '_queries/planet.fragment';
import { gql } from '@apollo/client';

export default gql`
  query planetsQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    planets(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...PlanetFragment
    }
  }

  ${PlanetFragment}
`;
