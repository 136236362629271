export * from './accessControl';

export const cleanupTimeout = () => {
  const tid = window['gtout'];
  if (tid) {
    clearTimeout(tid);
  }
};

export const safeTimeout = (fn: () => void, t: number) => {
  cleanupTimeout();
  setTimeout(() => {
    window['gtout'] = setTimeout(fn, t);
  }, 100);
};
