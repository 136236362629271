import {
  Stack,
  StackProps,
  Code,
  Image,
  Heading,
  Text,
  Box,
  Link,
} from '@chakra-ui/react';
import resolveImage from '_helper/resolveImage';
import { defaults } from 'chakra-ui-markdown-renderer';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

interface MarkdownProps extends StackProps {
  children: string;
}

const extendedTheme = {
  ...defaults,
  code: ({ children }) => {
    return (
      <Code color="blue.500">
        <Text color="black">{children}</Text>
      </Code>
    );
  },
  a: ({ children, ...rest }) => {
    return (
      <Link
        as="a"
        href={rest.href}
        isExternal
        textDecor="underline"
        cursor="pointer">
        {children}
      </Link>
    );
  },
  h4: ({ children }) => {
    return (
      <Heading as="h4" fontSize="1.25rem" color="blue.500">
        {children}
      </Heading>
    );
  },
  img: ({ node }) => {
    const { properties } = node;
    const { src, ...rest } = properties ?? {};
    return (
      <Box display="flex" justifyContent="center">
        <Image src={resolveImage('' + src)} {...rest} />
      </Box>
    );
  },
};

const Markdown: React.FC<MarkdownProps> = ({ children, ...rest }) => {
  return (
    <Stack spacing="1rem" {...rest}>
      <ReactMarkdown
        components={extendedTheme}
        remarkPlugins={[gfm]}
        children={children}
      />
    </Stack>
  );
};

export default Markdown;
