import {
  useLazyQuery,
  LazyQueryHookOptions,
  useQuery,
  QueryHookOptions,
} from '@apollo/client';
import eventsQueryString from '_queries/events.query';
import { eventsQuery, eventsQueryVariables } from '_queries/types/eventsQuery';

export const useEventLazyQuery = (
  options?: LazyQueryHookOptions<eventsQuery, eventsQueryVariables>,
) => {
  return useLazyQuery<eventsQuery, eventsQueryVariables>(
    eventsQueryString,
    options,
  );
};

export const useEventsQuery = (
  options?: QueryHookOptions<eventsQuery, eventsQueryVariables>,
) => {
  return useQuery<eventsQuery, eventsQueryVariables>(
    eventsQueryString,
    options,
  );
};
