import { ModalProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import create from 'zustand';

type GenericModal = {
  title: string;
  content: string | ReactNode;
  props?: Partial<ModalProps>;
};

interface IGenericModalStore {
  data?: GenericModal;
  isOpen: boolean;
  open: (data: GenericModal) => void;
  close: () => void;
}

export const useGenericModalStore = create<IGenericModalStore>((set) => ({
  data: null,
  isOpen: false,
  open: (data: GenericModal) => set(() => ({ isOpen: true, data })),
  close: () => set(() => ({ isOpen: false, data: null })),
}));
