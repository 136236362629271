import { useUserLazyQuery } from '_hooks';
import { parseIdFromToken } from '_utils';
import axios from 'axios';
import Cookie from 'js-cookie';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { useMount } from 'react-use';

export const usePingInterval = () => {
  const router = useRouter();
  const [fetchUser] = useUserLazyQuery();
  const pingTimeout = useRef(null);

  const ping = async () => {
    try {
      const token = Cookie.get('accessToken');
      if (token) {
        const userId = parseIdFromToken(token);
        fetchUser({
          variables: {
            id: userId,
          },
        });
        const { data } = await axios.post<{ shouldKick: boolean }>('/api/ping');
        if (data.shouldKick) {
          alert(
            "you've been detected login from another device, please log out from the other device to continue",
          );
          Cookie.remove('accessToken');
          await router.replace('/');
          window.location.reload();
        }
      }
    } catch {
    } finally {
      pingTimeout.current = setTimeout(
        ping,
        Number(process.env.PUBLIC_ENV_PING_INTERVAL) || 30000,
      );
    }
  };

  useMount(() => {
    ping();

    return () => {
      if (pingTimeout.current) {
        clearTimeout(pingTimeout.current);
      }
    };
  });
};
