import { gql } from '@apollo/client'

export default gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      username
      email
      provider
      access_level
      zoom_fallback
    }
  }
`;