import UploadFileFragment from './upload-file.fragment';
import { gql } from '@apollo/client';

export default gql`
  fragment SpeakerFragment on Speakers {
    id
    name
    profile_picture {
      ...UploadFileFragment
    }
    description
    biography
    created_at
    updated_at
    events {
      name
      start_date
      end_date
      content
      venue {
        title
        type
      }
    }
  }

  ${UploadFileFragment}
`;
