import {
  FormControl,
  Button,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Stack,
  StackProps,
  useToast,
} from '@chakra-ui/react';
import { useAccessControl } from '_hooks';
import useRedeemVoucherMutation from '_hooks/useRedeemVoucherMutation';
import { useUserQuery } from '_hooks/useUserLazyQuery';
import { useGenericModalStore } from '_store';
import { parseIdFromToken } from '_utils';
import Cookie from 'js-cookie';
import React, { useState, useMemo, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import InputMask from 'react-input-mask';

const VOUCHER_LENGTH = 13;
interface RedeemFormProps extends StackProps {
  helperText?: string;
  errorMessage?: string;
  callback?: () => void;
}

const RedeemForm: React.FC<RedeemFormProps> = ({
  helperText,
  errorMessage,
  callback,
  ...props
}) => {
  // Local State
  const [voucher, setVoucher] = useState('');
  const [captcha, setOpenCaptcha] = useState(false);
  const [isLoadCaptcha, setIsLoadCaptcha] = useState(false);
  const [recaptchaCode, setRecaptchaCode] = useState('');
  const { setUserLevel } = useAccessControl();
  const { fetchMore } = useUserQuery({ skip: true });

  // Custom Hooks
  const toast = useToast();
  const { close: closeModal } = useGenericModalStore();

  const refetchUserLevel = async () => {
    try {
      const token = Cookie.get('accessToken');
      if (token) {
        const userId = parseIdFromToken(token);
        const user: any = await fetchMore({
          variables: {
            id: userId,
          },
        });
        const userLevel = user?.data?.user?.access_level;
        setUserLevel(userLevel);
      }
    } catch (error) {
      window.location.reload();
    }
  };

  const onSuccessRedeem = (data) => {
    const status = data?.redeemVoucher?.status;
    const message = data?.redeemVoucher?.message;

    if (status === 'ERROR') {
      toast({
        title: 'Redeem Failed',
        description: message,
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top',
      });

      return;
    }

    // TODO: toast success
    toast({
      title: 'Redeem Success',
      description: 'Success Redeem your Ticket',
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
    closeModal();
  };

  const onErrorRedeem = () => {
    // TODO: toast error
    toast({
      title: 'Redeem Failed',
      description: 'Invalid Ticket Code',
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
  };

  const [redeemVoucher, { loading }] = useRedeemVoucherMutation({
    onCompleted: onSuccessRedeem,
    onError: onErrorRedeem,
  });

  const normalizedVoucher = useMemo(() => {
    return voucher.replace(/\-|\_/g, '').toUpperCase();
  }, [voucher]);

  const doCheckRecaptcha = () => setOpenCaptcha(true);

  const handleSubmit = async () => {
    try {
      await redeemVoucher({
        variables: {
          code: normalizedVoucher,
          recaptcha_code: recaptchaCode,
        },
      });
      refetchUserLevel();
      if (callback) {
        callback();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!isLoadCaptcha && recaptchaCode) {
      handleSubmit();
    }
  }, [isLoadCaptcha, recaptchaCode]);

  const verifyCallback = (response) => {
    if (response) {
      setIsLoadCaptcha(false);
      setRecaptchaCode(response);
      setOpenCaptcha(false);
    }
  };

  const onloadCallback = () => setIsLoadCaptcha(true);

  return (
    <Stack spacing="1.5rem" as="form" {...props}>
      <FormControl isInvalid={!!errorMessage}>
        <FormLabel>Ticket Code</FormLabel>
        <Input
          padding="0"
          fontSize="1.5rem"
          fontWeight="bold"
          value={voucher}
          onChange={(e) => {
            setVoucher(e.target.value);
          }}
          as={InputMask}
          mask="****-****-*****"
          textTransform="uppercase"
          _focus={{
            border: 'none',
            borderBottom: 'solid',
            borderBottomColor: props.color ?? 'white',
          }}
          border="none"
          borderRadius="0"
          bg="none"
          borderBottom="solid"
          borderBottomColor={props.color ?? 'white'}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
      {!!captcha && (
        <ReCAPTCHA
          style={{ display: 'inline-block' }}
          theme="dark"
          className="test"
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          onChange={verifyCallback}
          asyncScriptOnLoad={onloadCallback}
        />
      )}
      <Stack align="flex-end" justify="flex-end">
        <Button
          onClick={doCheckRecaptcha}
          isLoading={loading}
          disabled={normalizedVoucher.length < VOUCHER_LENGTH || isLoadCaptcha}
          colorScheme="blue">
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default RedeemForm;
