import { FeatureFlagContext, withFeatureToggleFn } from '_lib/featureFlag';
import _ from 'lodash';
import React, { useContext } from 'react';
import tawkTo from 'tawkto-react';

export const useTawk = () => {
  const ctx = useContext(FeatureFlagContext) ?? {};

  React.useEffect(() => {
    const noAction = () => {};
    const twk = () =>
      tawkTo(
        process.env.NEXT_PUBLIC_TAWK_PROPERTY_ID,
        process.env.NEXT_PUBLIC_TAWK_KEY,
      );

    withFeatureToggleFn('tawk')(noAction)(twk)(ctx)();
    withFeatureToggleFn('spakers-home')(noAction)(() => [
      { title: 'Home', path: '/' },
    ])(ctx)();

  }, [ctx]);

  const handleOverrideStyle = () => {
    const tawkElm = document.querySelector("iframe[title='chat widget']");
    if (tawkElm) {
      const bottomStyle = parseInt(tawkElm.style.bottom.replace('px', ''));
      const rightStyle = parseInt(tawkElm.style.right.replace('px', ''));

      if (bottomStyle > 20 && rightStyle > 16) {
        tawkElm.style.transform = null;
        tawkElm.style.left = null;
        tawkElm.style.bottom = null;
        tawkElm.setAttribute('class', 'custom-tawk');
      }
    }
  }

  const onResizeTawkButton = () => {
    setTimeout(() => {
      if (window && window.Tawk_API) {
        if(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent) ) {
          setTimeout(handleOverrideStyle, 100);
        }
      }
    }, 2500);
  }

  React.useEffect(() => {
    if (document.readyState === 'complete') {
      onResizeTawkButton();
    } else {
      window.addEventListener('load', onResizeTawkButton);

      return () => document.removeEventListener('load', onResizeTawkButton);
    }
  }, []);
};
