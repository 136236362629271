import EventFragment from '_queries/event.fragment';
import { gql } from '@apollo/client';

export default gql`
  query eventsQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    events(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...EventFragment
    }
  }

  ${EventFragment}
`;
