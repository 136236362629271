import VenueFragment from '_queries/venue.fragment';
import { gql } from '@apollo/client';

export default gql`
  query venuesQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    venues(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...VenueFragment
    }
  }

  ${VenueFragment}
`;
