import HexagonButton from './HexagonButton';
import {
  Text,
  Stack,
  StackItem,
  Button,
  Wrap,
  WrapItem,
  HeadingProps,
} from '@chakra-ui/react';
import CountdownRenderer from '_components/CountdownRenderer';
import Heading from '_components/DecoratedHeading';
import Markdown from '_components/Markdown';
import Speaker from '_components/SpeakerV2';
import { eventsQuery_events } from '_queries/types/eventsQuery';
import { getRelativeNow } from '_store/central';
import { isUpcomingEvent, isOngoingEvent } from '_utils/event';
import { format } from 'date-fns';
import React from 'react';
import Countdown from 'react-countdown';

export interface EventInformationProps extends HeadingProps {
  event: eventsQuery_events;
  speakersHeading?: string;
  isShowSpeakers?: boolean;
  onCheckin?: (eventId: string) => void;
}

const OngoingEventAction: React.FC<EventInformationProps> = ({
  event,
  onCheckin,
}) => {
  return (
    <HexagonButton
      mt="1rem"
      p="1rem 2rem"
      width="100%"
      onClick={() => onCheckin && onCheckin(event?.id)}>
      <Stack direction="row">
        <Text textTransform="uppercase" fontWeight="600">
          Check in now! Event Ends In
        </Text>
        <Countdown
          now={getRelativeNow}
          renderer={CountdownRenderer}
          date={new Date(event?.end_date)}
        />
      </Stack>
    </HexagonButton>
  );
};

const UpcomingEventAction: React.FC<EventInformationProps> = ({ event }) => {
  return (
    <Button
      mt="1rem"
      p="1rem 2rem"
      width="100%"
      onClick={() => undefined}
      disabled={true}>
      <Stack direction="row">
        <Text textTransform="uppercase" fontWeight="600">
          Event Starts In
        </Text>
        <Countdown
          now={getRelativeNow}
          renderer={CountdownRenderer}
          date={new Date(event?.start_date)}
        />
      </Stack>
    </Button>
  );
};

const EventInformation: React.FC<EventInformationProps> = ({
  event,
  speakersHeading,
  isShowSpeakers,
  textAlign,
  onCheckin,
}) => {
  const dateTime =
    event?.start_date &&
    format(new Date(event.start_date), 'EEEE, MMMM dd yyyy');
  const startEvent =
    event?.start_date && format(new Date(event.start_date), 'HH:mm');
  const endDateEvent =
    event?.end_date && format(new Date(event.end_date), 'HH:mm');

  if (!event) {
    return (
      <Text>Oops... Sorry spacemate, you've missed the awesome events!</Text>
    );
  }

  return (
    <>
      <Stack>
        <Heading>{event.name}</Heading>
        <StackItem mb="1.5rem">
          <Text textAlign={textAlign ?? 'left'} fontWeight="600">
            {dateTime}
          </Text>
          <Text textAlign={textAlign ?? 'left'}>
            {startEvent} - {endDateEvent} GMT+7
          </Text>
        </StackItem>
        <StackItem>
          <Markdown
            textOverflow="ellipsis"
            textAlign={textAlign ?? 'justify'}
            overflow="hidden"
            fontSize="14px"
            mt="0px"
            mb="1rem">
            {event?.content ?? ''}
          </Markdown>
        </StackItem>
        <StackItem>
          {isOngoingEvent(event.start_date, event.end_date) ? (
            <OngoingEventAction event={event} onCheckin={onCheckin} />
          ) : isUpcomingEvent(event.start_date) ? (
            <UpcomingEventAction event={event} />
          ) : null}
        </StackItem>
        {isShowSpeakers ? (
          <>
            <StackItem>
              <Heading
                fontSize="1rem"
                useBottomSeparator
                position="relative"
                mt="2.5rem"
                mb="1rem">
                {speakersHeading}
              </Heading>
            </StackItem>
            <Wrap spacing="2rem">
              {event?.speakers.map((speaker, idx) => (
                <WrapItem key={idx} w="110px">
                  <Speaker item={speaker} />
                </WrapItem>
              ))}
            </Wrap>
          </>
        ) : null}
      </Stack>
    </>
  );
};

EventInformation.defaultProps = {
  speakersHeading: 'SPEAKERS',
  isShowSpeakers: true,
};

export default EventInformation;
