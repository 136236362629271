import { withFeatureToggleFn } from '_lib/featureFlag';

const defaultNavItems = () => {
  return [
    { title: 'Home', path: '/' },
    { title: 'Blog', path: '/blogs' },
    { title: 'How To', path: '/how-to' },
    { title: "What's On", path: '/whatson' },
    { title: 'Buy Ticket', path: '/ticket' },
  ];
};

const noAction = () => [];

const withExploreNowNavItems = () => [
  { title: 'Redeem', path: '/redeem' },
  { title: 'Explore Now', path: '/intro' },
];

export const NAVIGATION_MENUS = (ctx) => [
  ...defaultNavItems(),
  ...withFeatureToggleFn('explore-now')(noAction)(withExploreNowNavItems)(
    ctx,
  )(),
];

export const PRIVATE_NAVIGATION_MENUS = (ctx) => [
  { title: 'Home', path: '/' },
  { title: 'Redeem', path: '/redeem' },
];

export const PUBLIC_ROUTES = [
  '/',
  '/blogs',
  '/blogs/*',
  '/how-to',
  '/redeem',
  '/whatson',
  '/ticket',
];
