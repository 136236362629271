import create from 'zustand';

interface IRestrictionModal {
  minimumLevel: number;
  isOpen: boolean;
  open: (minimumLevel: number) => void;
  close: () => void;
}

export const useRestrictionModalStore = create<IRestrictionModal>((set) => ({
  minimumLevel: 0,
  isOpen: false,
  open: (minimumLevel: number) => set(() => ({ isOpen: true, minimumLevel })),
  close: () => set(() => ({ isOpen: false, minimumLevel: 0 })),
}));
