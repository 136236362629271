import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Button,
  Box,
} from '@chakra-ui/react';
import Markdown from '_components/Markdown';
import { useGenericModalStore } from '_store';

export interface GenericModalProps {
  data: {
    title: string;
    content: string | React.ReactNode;
  };
}

const GenericModal: React.FC<Partial<ModalProps>> = (props) => {
  const { isOpen, data, close } = useGenericModalStore();
  const additionalProps = data?.props ?? {};

  return (
    <Modal
      size="3xl"
      {...additionalProps}
      isOpen={isOpen}
      onClose={close}
      {...props}>
      <Box position="relative" zIndex={999999999999}>
        <ModalOverlay />
        <ModalContent pt="20px">
          <ModalHeader fontSize="1.5rem">{data?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="80vh" overflowY="scroll">
            {typeof data?.content === 'string' ? (
              <Markdown>{data.content}</Markdown>
            ) : isOpen && data?.content ? (
              data?.content
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="blue" onClick={close}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Modal>
  );
};

export default GenericModal;
