import create from 'zustand';
import { speakerQuery_speakers } from '_queries/types/speakersQuery';

interface ISpeakerModalStore {
  speaker: speakerQuery_speakers | null;
  isOpen: boolean;
  open: (speaker: speakerQuery_speakers) => void;
  close: () => void;
}

export const useSpeakerModalStore = create<ISpeakerModalStore>((set) => ({
  speaker: null,
  isOpen: false,
  message: null,
  open: (speaker: speakerQuery_speakers) => set(() => ({ isOpen: true, speaker })),
  close: () => set(() => ({ isOpen: false, speaker: null }))
}));
