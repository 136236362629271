import { gql } from '@apollo/client'

export default gql`
  mutation redeemVoucher($code: String, $recaptcha_code: String) {
    redeemVoucher(code: $code, recaptcha_code: $recaptcha_code) {
      status
      message
    }
  }
`;
