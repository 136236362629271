import { AccessControlContext } from '_lib/accessControl';
import React from 'react';

/**
 *
 * @param level minimum level that required to access the resource
 * @returns collection functions to work with access control, eg: isGranted
 */
export const useAccessControl = (level?: number) => {
  const ctx = React.useContext(AccessControlContext);
  const isGranted = React.useMemo(
    () => (level ? ctx.userLevel >= level : true),
    [ctx, level],
  );
  const setUserLevel = ctx.setUserLevel;

  return { isGranted, userLevel: ctx.userLevel, setUserLevel };
};
