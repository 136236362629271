import { Box, Text, BoxProps, AspectRatio, Stack } from '@chakra-ui/react';
import OctagonImage from '_components/OctagonImage';
import { speakerQuery_speakers } from '_queries/types/speakersQuery';

export interface Props extends BoxProps {
  item: speakerQuery_speakers;
  presentAsRow?: boolean;
  speakerTitleColor?: string;
}

const Speaker: React.FC<Props> = ({
  item,
  presentAsRow,
  speakerTitleColor,
  ...props
}) => {
  return (
    <Stack w="100%" {...props}>
      <AspectRatio with="100%" ratio={1}>
        <OctagonImage objectFit="cover" src={item?.profile_picture?.url} />
      </AspectRatio>
      <Box margin="auto" justifyContent="center">
        <Text
          textShadow="2px 2px 6px rgba(0, 0, 0, .7)"
          pt="10px"
          fontSize="1rem"
          textTransform="uppercase"
          fontWeight="bold"
          alignSelf="center"
          textAlign="center">
          {item?.name}
        </Text>
        {item?.description?.split('\\n')?.map((desc, idx) => (
          <Text
            key={idx}
            textShadow="2px 2px 6px rgba(0, 0, 0, .7)"
            alignSelf="top"
            fontWeight="600"
            color={speakerTitleColor}
            textTransform="uppercase"
            margin="0 auto"
            mt="2px"
            fontSize="0.9rem"
            textAlign="center">
            {desc}
          </Text>
        ))}
      </Box>
    </Stack>
  );
};

Speaker.defaultProps = {
  presentAsRow: false,
  speakerTitleColor: '#00A7DB',
};

export default Speaker;
