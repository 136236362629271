import NotificationFragment from './notification.fragment';
import { gql } from '@apollo/client';

export default gql`
  query notificationQuery(
    $limit: Int
    $sort: String
    $start: Int
    $where: JSON
    $publicationState: PublicationState
  ) {
    notifications(
      limit: $limit
      sort: $sort
      start: $start
      where: $where
      publicationState: $publicationState
    ) {
      ...NotificationFragment
    }
  }

  ${NotificationFragment}
`;
