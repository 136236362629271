import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-unfetch';
import Cookies from 'js-cookie';
import _uniqBy from 'lodash/uniqBy';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get('accessToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const createApolloClient = (
  initialState: NormalizedCacheObject = {},
) => {
  const fetchOptions = {
    agent: null,
  };

  // If you are using a https_proxy, add fetchOptions with 'https-proxy-agent' agent instance
  // 'https-proxy-agent' is required here because it's a sever-side only module
  if (typeof window === 'undefined') {
    if (process.env.https_proxy) {
      fetchOptions.agent = new (require('https-proxy-agent'))(
        process.env.https_proxy,
      );
    }
  }

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
    credentials: 'same-origin',
    fetch,
    fetchOptions,
  });

  return new ApolloClient({
    ssrMode: false,
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            blogs: {
              keyArgs: ['sort', 'where'],
              merge(existing, incoming, { args: { start = 0 } }) {
                // Slicing is necessary because the existing data is
                // immutable, and frozen in development.
                const merged = existing ? existing.slice(0) : [];
                for (let i = 0; i < incoming.length; ++i) {
                  merged[start + i] = incoming[i];
                }
                return _uniqBy(merged, '__ref');
              },
            },
          },
        },
      },
    }).restore(initialState),
  });
};
