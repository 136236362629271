import { Provider as ResponsiveImageProvider } from '@adeyahya/rrp';
import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import Unleash, { Metadata, IConfig } from '@kompasfest/f9g-adapter-unleash';
import FeatureToggle, { IFeatureToggle } from '@kompasfest/f9g-core';
import Root from '_components/Root';
import { PORTAL_ZINDEX } from '_constants/portal';
import { AccessControlProvider } from '_lib';
import { withApollo } from '_lib/apollo';
import { FeatureToggleProvider } from '_lib/featureFlag';
import * as ga from '_lib/ga';
import Cookie from 'js-cookie';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

const isTesting = Cookie.get('isTesting');

const flagConfig: IConfig = {
  url: process.env.NEXT_PUBLIC_F9G_URL,
  appName:
    isTesting && isTesting === 'true'
      ? 'testing'
      : process.env.NEXT_PUBLIC_F9G_APP_NAME,
  instanceId: process.env.NEXT_PUBLIC_F9G_INTANCE_ID,
};

const ffClient: IFeatureToggle<Metadata> = new FeatureToggle({
  adapter: new Unleash(flagConfig),
  strategy: {
    type: 'poll',
    pollInterval: 15 * 1000 * 60, //milisec
  },
});

const breakpoints = createBreakpoints({
  xs: '320px',
  sm: '420px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
});

export const extendedTheme = extendTheme({
  components: {
    Modal: {
      baseStyle: {
        header: {
          fontFamily: 'aAtmospheric, Montserrat, system-ui, sans-serif',
          fontSize: '1rem',
          _after: {
            mt: '0.5rem',
            content: "''",
            width: '70px',
            height: '4px',
            bg: 'white',
            display: 'block',
          },
        },
      },
      defaultProps: {
        isCentered: true,
      },
    },
    Drawer: {
      baseStyle: {
        header: {
          fontFamily: 'aAtmospheric, Montserrat, system-ui, sans-serif',
          fontSize: '1rem',
          _after: {
            mt: '0.5rem',
            content: "''",
            width: '70px',
            height: '4px',
            bg: 'white',
            display: 'block',
          },
        },
      },
    },
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  breakpoints,
  fonts: {
    body: 'Montserrat, system-ui, sans-serif',
    heading: 'Montserrat, system-ui, sans-serif',
  },
  colors: {
    blue: {
      50: '#e8f0ff',
      100: '#c3d1f1',
      200: '#9db2e2',
      300: '#7793d5',
      400: '#5175c8',
      500: '#2e4d94',
      600: '#2a4788',
      700: '#1d3362',
      800: '#0f1e3d',
      900: '#020a1a',
    },
    bluesky: {
      50: '#d7fcff',
      100: '#abeeff',
      200: '#7ae2ff',
      300: '#48d5ff',
      400: '#1ac9ff',
      500: '#00a7db',
      600: '#0088b4',
      700: '#006182',
      800: '#003b51',
      900: '#001520',
    },
    pink: {
      50: '#ffe7f8',
      100: '#f4bfe1',
      200: '#e997ca',
      300: '#de6eb4',
      400: '#d3459e',
      500: '#cc3092',
      600: '#922167',
      700: '#68164b',
      800: '#410b2d',
      900: '#1b0112',
    },
  },
});

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <link
          rel="canonical"
          href={'https://kompasfest.kompas.id' + router.asPath}
        />
        <meta
          property="og:url"
          content={'https://kompasfest.kompas.id' + router.asPath}
        />
        <meta
          name="twitter:url"
          content={'https://kompasfest.kompas.id' + router.asPath}
        />
      </Head>

      <ChakraProvider theme={extendedTheme} portalZIndex={PORTAL_ZINDEX}>
        <FeatureToggleProvider adapter={ffClient}>
          <AccessControlProvider>
            <ResponsiveImageProvider>
              <Root>
                <Box
                  position="fixed"
                  top="0"
                  left="0"
                  w="100%"
                  h="100vh"
                  zIndex="-2"
                  bg="#081a35"
                />
                <Component {...pageProps} />
              </Root>
            </ResponsiveImageProvider>
          </AccessControlProvider>
        </FeatureToggleProvider>
      </ChakraProvider>
    </>
  );
}

export default withApollo({ ssr: false })(App);
