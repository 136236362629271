import { useCentralStore } from '../store/central';
import { useAccessControl } from './useAccessControl';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { AspectRatio, Stack, StackItem, Text } from '@chakra-ui/react';
import DecoratedHeading from '_components/DecoratedHeading';
import EmptyStateEvent from '_components/EmptyStateEvent';
import EventCard from '_components/EventCard';
import EventInformation from '_components/EventInformation';
import HardSellingSultan from '_components/HardSellingSultan';
import eventsQueryString from '_queries/events.query';
import { eventsQuery, eventsQueryVariables } from '_queries/types/eventsQuery';
import {
  useGenericDrawerStore,
  useGenericModalStore,
  useRestrictionModalStore,
} from '_store';
import { isPastEvent, isUpcomingEvent, isOngoingEvent } from '_utils/event';
import getVideoId from 'get-video-id';
import { useMemo, useCallback } from 'react';

export const useEventsQuery = (
  options?: QueryHookOptions<eventsQuery, eventsQueryVariables> & {
    speakersHeading?: string;
  },
) => {
  const { now } = useCentralStore();
  const { userLevel } = useAccessControl();
  const { open: openRestrictionModal } = useRestrictionModalStore();
  const { open: setOpenModal } = useGenericModalStore();
  const { open: setOpenDrawer } = useGenericDrawerStore();
  const { data, ...rest } = useQuery<eventsQuery, eventsQueryVariables>(
    eventsQueryString,
    options,
  );

  const pastEvents = useMemo(() => {
    return data?.events?.filter((item) => isPastEvent(item.end_date)) ?? [];
  }, [data, now]);

  const events = useMemo(() => {
    return data?.events?.filter((item) => isUpcomingEvent(item.start_date));
  }, [data?.events, now]);

  const ongoingEvent = useMemo(() => {
    return data?.events?.find((item) =>
      isOngoingEvent(item.start_date, item.end_date),
    );
  }, [data?.events, now]);

  const nextEvent = events?.[0];

  const handleCheckin = useCallback(
    (eventId: string) => {
      if (ongoingEvent && !isNaN(Number(ongoingEvent.min_access_level))) {
        const minimumLevel = Number(ongoingEvent.min_access_level);
        if (minimumLevel > 0 && userLevel < minimumLevel) {
          openRestrictionModal(minimumLevel);
          return;
        }
      }

      if (ongoingEvent?.youtube_url) {
        const ytVideoId = getVideoId(ongoingEvent?.youtube_url);
        setOpenModal({
          title: ongoingEvent?.name || 'INFORMATION',
          content: (
            <AspectRatio ratio={4 / 2}>
              <iframe
                width="100%"
                src={`https://www.youtube-nocookie.com/embed/${ytVideoId?.id}?autoplay=1&modestbranding=1&showinfo=0&rel=0`}
                title="YouTube Video Player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </AspectRatio>
          ),
        });
      } else {
        window.open('/api/zoom?event_id=' + eventId, '_blank');
      }
    },
    [ongoingEvent, events, userLevel],
  );

  const handleOpenEventInformation = useCallback(
    (eventId) => {
      const event = events.find((evt) => evt.id === eventId);
      setOpenModal({
        title: event?.name || 'INFORMATION',
        content: (
          <EventInformation
            onCheckin={handleCheckin}
            event={event}
            speakersHeading={options.speakersHeading ?? 'SPEAKERS'}
          />
        ),
      });
    },
    [events],
  );

  const handleRewatch = useCallback(
    (eventId) => {
      const event = pastEvents.find((evt) => evt.id === eventId);
      setOpenModal({
        title: event?.name || 'INFORMATION',
        content: (
          <>
            <EmptyStateEvent />
            <EventInformation
              onCheckin={handleCheckin}
              event={event}
              speakersHeading={options.speakersHeading ?? 'SPEAKERS'}
            />
          </>
        ),
      });
    },
    [pastEvents, userLevel],
  );

  const handleOpenGenericModal = useCallback(() => {
    const event = ongoingEvent || nextEvent;
    setOpenModal({
      title: event?.name || 'INFORMATION',
      content: event ? (
        <EventInformation
          onCheckin={handleCheckin}
          event={event}
          speakersHeading={options.speakersHeading ?? 'SPEAKERS'}
        />
      ) : (
        <Text textAlign="center" my="5rem">
          {/* @TODO: Empty State Component */}
          {'Sorry, No Event'}
        </Text>
      ),
    });
  }, [ongoingEvent, nextEvent]);

  const handleOpenDrawerModal = useCallback(() => {
    setOpenDrawer({
      title: 'SCHEDULE',
      content: (
        <Stack direction="column" spacing="1.5rem">
          <StackItem>
            <Stack>
              <StackItem>
                <DecoratedHeading
                  fontSize="1.25rem"
                  textAlign="center"
                  width="100%"
                  margin="1rem 0">
                  Ongoing and Upcoming
                </DecoratedHeading>
              </StackItem>
              {ongoingEvent && (
                <EventCard
                  onCheckin={handleCheckin}
                  item={ongoingEvent}
                  started
                />
              )}
              {events?.map((event) => (
                <EventCard
                  key={event.id}
                  onCheckin={handleOpenEventInformation}
                  item={event}
                />
              ))}

              {!ongoingEvent && events?.length === 0 ? (
                <>
                  <Text>Sorry, you've missed the awesome events</Text>
                  <HardSellingSultan userLevel={userLevel} />
                </>
              ) : null}
            </Stack>
          </StackItem>

          {pastEvents?.length > 0 ? (
            <StackItem>
              <Stack>
                <StackItem>
                  <DecoratedHeading
                    fontSize="1.25rem"
                    textAlign="center"
                    width="100%"
                    margin="1rem 0">
                    Past Event
                  </DecoratedHeading>
                </StackItem>
                {pastEvents?.map((event) => (
                  <EventCard
                    key={event.id}
                    onCheckin={handleRewatch}
                    item={event}
                  />
                ))}
              </Stack>
            </StackItem>
          ) : null}
        </Stack>
      ),
    });
  }, [events, ongoingEvent, pastEvents]);

  return {
    pastEvents,
    events,
    ongoingEvent,
    nextEvent,
    handleCheckin,
    handleOpenEventInformation,
    handleOpenGenericModal,
    handleOpenDrawerModal,
    ...rest,
  };
};
