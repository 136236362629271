import { Box, Heading, HeadingProps } from '@chakra-ui/layout';
import React from 'react';

export interface BreakingPoints {
  lg: string;
  md: string;
  xs: string;
}

export interface DecoratedHeading extends HeadingProps {
  useBottomSeparator?: boolean;
  withShadow?: boolean;
  widthLine?: string | BreakingPoints;
  heightLine?: string;
  itemPosition?: string | BreakingPoints;
}

const DecoratedHeading: React.FC<DecoratedHeading> = ({
  children,
  useBottomSeparator,
  withShadow,
  widthLine,
  heightLine,
  itemPosition = 'left',
  ...props
}) => {
  const shadow = '3px 5px 9px rgb(0 0 0 / 48%)';

  return (
    <Box display="inline-block" {...props}>
      <Heading
        fontFamily="aAtmospheric, Montserrat, system-ui, sans-serif"
        as="h2"
        textShadow={withShadow && shadow}
        fontSize={props.fontSize}
        color={props.color}
        children={children}
        textAlign="center"
      />
      {useBottomSeparator && (
        <Box
          boxShadow={withShadow && shadow}
          h={heightLine || '.4rem'}
          mt=".5rem"
          w={widthLine || '40%'}
          bg={props.color}
          float={itemPosition}
        />
      )}
    </Box>
  );
};

DecoratedHeading.defaultProps = {
  fontSize: '3xl',
  useBottomSeparator: true,
};

export default DecoratedHeading;
