import { gql } from '@apollo/client';
import AssetCollectionFragment from '_queries/asset-collection.fragment';
import SpeakerFragment from '_queries/speaker.fragment';

export default gql`
  fragment EventFragment on Events {
    id
    name
    slug
    start_date
    end_date
    content
    created_at
    updated_at
    min_access_level
    assetCollections {
      ...AssetCollectionFragment
    }
    speakers {
      ...SpeakerFragment
    }
    venue {
      title
      type
      slug
      building {
        slug
      }
    }
    youtube_url
  }
  ${AssetCollectionFragment}
  ${SpeakerFragment}
`;
