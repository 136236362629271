import { gql } from '@apollo/client';
import AssetCollectionFragment from '_queries/asset-collection.fragment'
import BuildingFragment from '_queries/building.fragment'

export default gql`
  fragment PlanetFragment on Planets {
    id
    title
    subtitle
    slug
    content
    created_at
    updated_at
    assetCollections {
      ...AssetCollectionFragment
    }
    buildings {
      ...BuildingFragment
    }
  }
  ${AssetCollectionFragment}
  ${BuildingFragment}
`;
