import { gql } from '@apollo/client';
import AssetCollectionFragment from '_queries/asset-collection.fragment'
import VenueFragment from '_queries/venue.fragment'

export default gql`
  fragment BuildingFragment on Buildings {
    id
    title
    subtitle
    slug
    content
    created_at
    updated_at
    assetCollections {
      ...AssetCollectionFragment
    }
    venues {
      ...VenueFragment
    }
  }
  ${AssetCollectionFragment}
  ${VenueFragment}
`;
