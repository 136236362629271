import { gql } from '@apollo/client';
import SpeakerFragment from '_queries/speaker.fragment';

export default gql`
  query GetSpeakers {
    speakers(sort: "name:asc") {
      ...SpeakerFragment
    }
  }

  ${SpeakerFragment}
`;
