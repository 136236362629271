import { gql } from '@apollo/client';
import EventFragment from '_queries/event.fragment';

export default gql`
  query eventQuery($id: ID!) {
    event(id: $id) {
      ...EventFragment
    }
  }

  ${EventFragment}
`;
