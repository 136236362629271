// @ts-ignore
import notificationSound from '_assets/notification.wav';
import { notificationQuery_notifications } from '_queries/types/notificationQuery';
import compareAsc from 'date-fns/compareAsc';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import uniq from 'lodash/uniq';
import _uniq from 'lodash/uniq';

export const getReadedIdList = () => {
  try {
    const stringData = window.localStorage.getItem('marked-notification');
    return (JSON.parse(stringData) as string[]) ?? [];
  } catch {
    return [];
  }
};

export const updateReadedList = (list: string | string[]) => {
  try {
    const readedList = getReadedIdList();
    const newList = (() => {
      if (Array.isArray(list)) {
        return uniq([...readedList, ...list]);
      }

      return uniq([...readedList, list]);
    })();

    window.localStorage.setItem('marked-notification', JSON.stringify(newList));
  } catch (error) {}
};

export const getNotificationSegments = (
  notifications: notificationQuery_notifications[],
  now: number,
) => {
  const readedList = getReadedIdList();
  const unreadedList: notificationQuery_notifications[] = [];
  const earlierList: notificationQuery_notifications[] = [];
  let toBePushed: null | notificationQuery_notifications = null;

  notifications.forEach((item) => {
    const isFuture = compareAsc(new Date(item.time_to_published), now) === 1;
    const diffInSec = differenceInSeconds(
      new Date(item.time_to_published),
      now,
    );
    const isReaded = readedList.includes(item.id);

    if (!isReaded && diffInSec <= 59 && diffInSec >= -59) {
      unreadedList.push(item);
      toBePushed = item;
      return;
    }

    if (isFuture) return;

    if (isReaded) {
      earlierList.push(item);
    } else {
      unreadedList.push(item);
    }
  });

  return {
    unread: unreadedList,
    earlier: earlierList,
    toBePushed,
  };
};

export const playNotificationSound = () => {
  if (!window['notifAudio']) {
    window['notifAudio'] = new Audio(notificationSound);
  }
  window['notifAudio'].play();
};
