import { AspectRatio, Image, Box, ImageProps } from '@chakra-ui/react';
import React from 'react';

export interface HexagonImageProps extends ImageProps {}

const HexagonImage: React.FC<HexagonImageProps> = (props) => {
  return (
    <AspectRatio
      clipPath="polygon(15% 0, 85% 0, 95% 10%, 95% 90%, 85% 100%, 15% 100%, 5% 90%, 5% 10%);"
      w="100%"
      ratio={1}>
      <Box bg="rgb(163 235 255 / 54%)" display="inline-block">
        <Image
          w="calc(100% - 5px)"
          h="calc(100% - 5px)"
          clipPath="polygon(15% 0, 85% 0, 95% 10%, 95% 90%, 85% 100%, 15% 100%, 5% 90%, 5% 10%);"
          objectFit="cover"
          {...props}
        />
      </Box>
    </AspectRatio>
  );
};

export default HexagonImage;
