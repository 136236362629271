const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return 'a few moment';
  } else {
    // Render a countdown
    return (
      <span>
        {`${days ? `${days}d ` : ''}${hours ? `${hours}hr ` : ''}${
          minutes ? `${minutes}min ` : ''
        }${seconds ? `${seconds}s` : ''}`}
      </span>
    );
  }
};

export default CountdownRenderer;
