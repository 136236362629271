const CDN_URL = process.env.NEXT_PUBLIC_CDN;

if (!CDN_URL) {
  throw new Error('please provide envar NEXT_PUBLIC_CDN');
}

const resolveImage = (path: string) => {
  if (path?.match(/^https?:/)) {
    return path;
  }

  const base = CDN_URL.match(/\/$/) ? CDN_URL.slice(0, -1) : CDN_URL;

  return `${base}${path}`;
};

export default resolveImage;
