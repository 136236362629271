import { notificationQuery_notifications } from '_queries/types/notificationQuery';
import create from 'zustand';

interface INotificationStore {
  historyNotifications: notificationQuery_notifications[];
  ongoingNotification: notificationQuery_notifications | null;
  updateOngoingNotification: (
    notification: notificationQuery_notifications,
  ) => void;
  setIsNotified: () => void;
  isNotified: boolean;
}

export const useNotificationStore = create<INotificationStore>((set) => ({
  historyNotifications: [],
  ongoingNotification: null,
  isNotified: false,
  setIsNotified: () => {
    set(() => ({
      isNotified: true,
    }));
  },
  updateOngoingNotification: (notification) => {
    set((state) => {
      const prevNotification = { ...state.ongoingNotification };

      return {
        ongoingNotification: notification,
        isNotified: false,
        historyNotifications:
          state.historyNotifications.concat(prevNotification),
      };
    });
  },
}));
