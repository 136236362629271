import { Box, Text, BoxProps } from '@chakra-ui/react';
import OctagonImage from '_components/OctagonImage';
import { speakerQuery_speakers } from '_queries/types/speakersQuery';

export interface Props extends BoxProps {
  item: speakerQuery_speakers;
  presentAsRow?: boolean;
  speakerTitleColor?: string;
}

const Speaker: React.FC<Props> = ({
  item,
  presentAsRow,
  speakerTitleColor,
  ...props
}) => {
  const widthPic = {
    xs: '10rem',
    md: '18rem',
  };

  return (
    <Box
      w="100%"
      display="flex"
      flexDirection={{ xs: !presentAsRow ? 'row' : 'column', md: 'column' }}
      {...props}>
      <Box
        margin="auto"
        width={{
          xs: !presentAsRow ? widthPic.xs : widthPic.md,
          md: widthPic.md,
        }}
        padding={{
          xs: !presentAsRow ? '0 0.75rem 0 0' : '0 2rem',
          md: '0 2rem',
        }}>
        <OctagonImage objectFit="cover" src={item?.profile_picture?.url} />
      </Box>
      <Box
        margin="auto"
        justifyContent="center"
        width={{
          xs: !presentAsRow ? `calc(100% - ${widthPic.xs})` : widthPic.md,
          md: widthPic.md,
        }}>
        <Text
          textShadow="2px 2px 6px rgba(0, 0, 0, .7)"
          pt="10px"
          fontSize="1rem"
          textTransform="uppercase"
          fontWeight="bold"
          alignSelf="center"
          textAlign={{ xs: !presentAsRow ? 'left' : 'center', md: 'center' }}>
          {item?.name}
        </Text>
        {item?.description?.split('\\n')?.map((desc, idx) => (
          <Text
            key={idx}
            textShadow="2px 2px 6px rgba(0, 0, 0, .7)"
            alignSelf="top"
            fontWeight="600"
            color={speakerTitleColor}
            textTransform="uppercase"
            margin="0 auto"
            mt="2px"
            fontSize="0.9rem"
            textAlign={{ xs: !presentAsRow ? 'left' : 'center', md: 'center' }}>
            {desc}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

Speaker.defaultProps = {
  presentAsRow: false,
  speakerTitleColor: '#00A7DB',
};

export default Speaker;
