import { gql } from '@apollo/client';
import AssetCollectionFragment from '_queries/asset-collection.fragment';
import EventFragment from '_queries/event.fragment';

export default gql`
  fragment VenueFragment on Venues {
    id
    title
    subtitle
    slug
    content
    created_at
    updated_at
    type
    assetCollections {
      ...AssetCollectionFragment
    }
    events {
      ...EventFragment
    }
    actions {
      ... on ComponentActionsRedirect {
        title
        link
        __typename
      }
      ... on ComponentActionsMarkdown {
        title
        content
        __typename
      }
    }
    position
  }
  ${AssetCollectionFragment}
  ${EventFragment}
`;
