import { useAccessControl } from './useAccessControl';
import { useUserQuery } from './useUserLazyQuery';
import { PUBLIC_ROUTES } from '_constants/navigation';
import { useLoginModalStore } from '_store';
import { parseIdFromToken } from '_utils';
import Cookie from 'js-cookie';
import minimatch from 'minimatch';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useMount } from 'react-use';

let isClient = false;

export const useAuth = () => {
  const router = useRouter();
  const { setUserLevel } = useAccessControl();
  const [loading, setLoading] = useState(false);
  const { open, setMessage } = useLoginModalStore();
  const {
    data: userData,
    fetchMore,
    loading: userLoading,
  } = useUserQuery({
    variables: {
      id: '-1',
    },
  });

  const authCheck = async () => {
    try {
      const token = Cookie.get('accessToken');
      if (token) {
        const userId = parseIdFromToken(token);
        const user: any = await fetchMore({
          variables: {
            id: userId,
          },
        });
        const accessLevel = user?.data?.user?.access_level;
        return isNaN(Number(accessLevel)) ? 0 : Number(accessLevel);
      }

      return null;
    } catch (error) {
      return null;
    }
  };

  const pathProtectionCheck = () => {
    const sanitizedPath = router.asPath.split('?')[0];
    const isProtected = !PUBLIC_ROUTES.some((item) =>
      minimatch(sanitizedPath, item),
    );

    if (isClient) {
      authCheck().then((accessLevel) => {
        setUserLevel(accessLevel ? Number(accessLevel) : -1);

        if (
          accessLevel === null &&
          isProtected &&
          !router.asPath?.includes('/auth') &&
          // path for error like 404, 500
          router.pathname !== '/_error'
        ) {
          setMessage(`please login before accessing ${router.asPath}`);
          open();
          router.replace('/').then(() => {
            setLoading(false);
          });
        }
      });
    }
  };

  useMount(() => {
    isClient = true;
    pathProtectionCheck();
  });

  useEffect(() => {
    pathProtectionCheck();
  }, [router, userData]);

  return loading || userLoading;
};
