import BuildingFragment from '_queries/building.fragment';
import { gql } from '@apollo/client';

export default gql`
  query buildingsQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    buildings(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...BuildingFragment
    }
  }

  ${BuildingFragment}
`;
