import { useToast, Box, Text } from '@chakra-ui/react';
import HexagonButton from '_components/HexagonButton';
import Markdown from '_components/Markdown';
import { useEventsQuery } from '_hooks/useEventsLazyQuery';
import useNotificationsQuery from '_hooks/useNotifiactionsQuery';
import { useVenuesQuery } from '_hooks/useVenuesQuery';
import {
  getNotificationSegments,
  updateReadedList,
  playNotificationSound,
} from '_lib/notification';
import { eventsQuery_events } from '_queries/types/eventsQuery';
import { notificationQuery_notifications } from '_queries/types/notificationQuery';
import { venuesQuery_venues } from '_queries/types/venuesQuery';
import { isUpcomingEvent } from '_utils/event';
import axios from 'axios';
import diffInMinutes from 'date-fns/differenceInMinutes';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import create from 'zustand';
import { persist } from 'zustand/middleware';

let isServerTimeDisabled = true;
let intervalId = null;
let intervalRelativeTimeId = null;
let diffTimeWithServer = 0;
const notifiedEventIds = [];

interface IData {
  now: number;
  events: eventsQuery_events[];
  eventsLoading: boolean;
  venues: venuesQuery_venues[];
  venuesLoading: boolean;
  notificationsLoading: boolean;
  notifications: notificationQuery_notifications[];
  unreadedNotifications: notificationQuery_notifications[];
  readedNotifications: notificationQuery_notifications[];
  notificationToBePushed?: notificationQuery_notifications;
}

export interface IStore extends IData {
  update: (state: Partial<IData>) => void;
}

export const getRelativeNow = () => {
  const localNow = new Date().getTime();
  if (isServerTimeDisabled) return localNow;
  return localNow + diffTimeWithServer;
};

/**
 * central store for subscribe any data
 * that relates to events and notifications
 */
export const useCentralStore = create<IStore>(
  persist(
    (set) => ({
      now: new Date().getTime(),
      events: [],
      eventsLoading: false,
      venues: [],
      venuesLoading: false,
      notificationsLoading: false,
      notifications: [],
      unreadedNotifications: [],
      readedNotifications: [],
      notificationToBePushed: null,
      update: (next: Partial<IData>) => {
        set(next as IData);
      },
    }),
    {
      name: 'central-store',
    },
  ),
);

// recalculate unread and readed notifications
export const recalculateNotification = () => {
  const { notifications, now, update } = useCentralStore.getState();
  const { unread, earlier, toBePushed } = getNotificationSegments(
    notifications,
    now,
  );

  update({
    unreadedNotifications: unread,
    readedNotifications: earlier,
    notificationToBePushed: toBePushed,
  });
};

export const useCentralFetcher = () => {
  const toast = useToast();
  const router = useRouter();
  const { update } = useCentralStore.getState();
  const { data: notificationsData, loading: notificationsLoading } =
    useNotificationsQuery();
  const { data: venuesData, loading: venuesLoading } = useVenuesQuery();
  const { data: eventsData, loading: eventsLoading } = useEventsQuery({
    variables: {
      sort: 'start_date:asc',
    },
  });

  // update time relative to diff with server
  useEffect(() => {
    if (!window['____disable-server-time____']) {
      window['____disable-server-time____'] = () => {
        isServerTimeDisabled = true;
      };
    }

    const updateTime = () => {
      const localTime = new Date().getTime();
      update({
        now: isServerTimeDisabled
          ? new Date().getTime()
          : localTime + diffTimeWithServer,
      });
    };

    updateTime();
    intervalRelativeTimeId = setInterval(() => {
      updateTime();
    }, 30000);

    return () => {
      if (intervalRelativeTimeId) {
        clearInterval(intervalRelativeTimeId);
      }
    };
  }, []);

  // time syncer interval
  useEffect(() => {
    syncServerTime();
    intervalId = setInterval(() => {
      try {
        syncServerTime();
      } catch {}
    }, 2 * 60000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  // subscribe push notification data
  useEffect(() => {
    return useCentralStore.subscribe(
      (state: IStore) => {
        if (state.notificationToBePushed) {
          const isHubNotification =
            state.notificationToBePushed?.venue?.type === 'HUB';
          if (isHubNotification && window.location.pathname === '/hub') {
            // do nothing, this notification will be handled by 3d scene
          } else {
            // notify using toast
            playNotificationSound();
            toast({
              title: 'Notification',
              description: (
                <Box
                  onPointerEnter={() => {
                    updateReadedList(state.notificationToBePushed.id);
                  }}>
                  <Markdown
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontWeight="semibold"
                    fontSize="1.2rem">
                    {state.notificationToBePushed.content}
                  </Markdown>
                </Box>
              ),
              position: 'top',
              status: 'info',
              isClosable: true,
              duration: 15000,
            });
          }
        }
      },
      (state) => state,
      (prev, next) =>
        prev.notificationToBePushed === next.notificationToBePushed,
    );
  }, []);

  // event reminder
  useEffect(() => {
    const notify = (event: eventsQuery_events, actionLink?: string) => {
      if (actionLink === window.location.pathname) {
        return;
      }

      playNotificationSound();
      toast({
        title: 'Notification',
        description: (
          <Box>
            <Text mt="1rem">
              Yuk siap siap join <Text fontWeight="bold">{event.name}</Text>{' '}
              akan dimulai dalam 5 menit
            </Text>
            {actionLink ? (
              <HexagonButton
                mt="1rem"
                onClick={() => {
                  router.push(actionLink);
                }}>
                Go To {event?.venue?.type}
              </HexagonButton>
            ) : null}
          </Box>
        ),
        position: 'top',
        status: 'info',
        isClosable: true,
        duration: 10000,
      });
      notifiedEventIds.push(event.id);
    };

    const calculateEventReminder = (state: IStore) => {
      const eventsAboutToStarts = state.events.filter((item) => {
        const isItUpcoming = isUpcomingEvent(item.start_date);
        if (isItUpcoming && !notifiedEventIds.includes(item.id)) {
          const diff = diffInMinutes(
            new Date(item.start_date),
            new Date(state.now),
          );
          if (Math.abs(diff) === 5) {
            return true;
          }
        }

        return false;
      });

      eventsAboutToStarts.forEach((item) => {
        let actionLink = null;
        switch (item?.venue?.type) {
          case 'KLASS':
            actionLink = `/klass/${item?.venue?.slug}`;
            break;
          case 'SHOW':
            actionLink = `/show`;
            break;
          case 'CONFERENCE':
            actionLink = `/conference`;
            break;
        }
        notify(item, actionLink);
      });
    };

    calculateEventReminder(useCentralStore.getState());
    return useCentralStore.subscribe(
      (state: IStore) => {
        calculateEventReminder(state);
      },
      (state) => state,
      (prev, next) => prev.now === next.now,
    );
  }, []);

  // subscribe on time and notifications change to
  // recalculate notifaction segment
  useEffect(() => {
    return useCentralStore.subscribe(
      (_: IStore) => {
        recalculateNotification();
      },
      (state) => state,
      (prev, next) =>
        prev.notifications === next.notifications && prev.now === next.now,
    );
  }, []);

  // update venues store based on network
  useEffect(() => {
    update({
      venuesLoading,
    });

    if (venuesData?.venues) {
      update({
        venues: venuesData?.venues ?? [],
      });
    }
  }, [venuesData, venuesLoading]);

  // update notification store based on network
  useEffect(() => {
    update({
      notificationsLoading,
    });

    if (notificationsData?.notifications) {
      update({ notifications: notificationsData?.notifications ?? [] });
    }

    if (!notificationsLoading) {
      // first time calculation happens here
      recalculateNotification();
    }
  }, [notificationsData, notificationsLoading]);

  // update events store based on network
  useEffect(() => {
    update({
      eventsLoading,
    });

    if (eventsData?.events) {
      update({
        events: eventsData?.events ?? [],
      });
    }
  }, [eventsData, eventsLoading]);
};

const syncServerTime = async () => {
  const { update } = useCentralStore.getState();
  try {
    const res = await axios.get('/api/sync-time');
    const newTime = new Date(res?.data?.now).getTime();
    if (!isNaN(Number(newTime))) {
      const localTime = new Date().getTime();
      const diff = Math.abs(newTime - localTime);
      if (newTime > localTime) {
        diffTimeWithServer = diff;
      } else {
        diffTimeWithServer = -diff;
      }

      update({
        now: isServerTimeDisabled
          ? new Date().getTime()
          : localTime + diffTimeWithServer,
      });
    }
  } catch {}
};
