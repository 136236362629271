import { QueryHookOptions, useQuery } from '@apollo/client';
import speakerQuery from '_queries/speakers.query';
import { speakerQuery_types } from '_queries/types/speakersQuery';

const useSpeakersQuery = (
  options: QueryHookOptions<speakerQuery_types, {}>,
) => {
  const { data, ...rest } = useQuery<speakerQuery_types, {}>(speakerQuery, options);

  return {
    ...rest,
    data: data?.speakers ?? null,
  };
};

export default useSpeakersQuery;
