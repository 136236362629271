import {
  Box,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  Stack,
  Text,
  Button,
} from '@chakra-ui/react';
import RedeemForm from '_components/RedeemForm';
import { USER_TIERS } from '_constants/tiers';
import { useRestrictionModalStore } from '_store';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

const RestrictionModal: React.FC = () => {
  const [onRedeem, setOnRedeem] = useState(false);
  const { isOpen, close, minimumLevel } = useRestrictionModalStore();
  const router = useRouter();

  const handleBuyTicket = () => {
    router.replace('/ticket').finally(() => {
      close();
    });
  };

  const handleRedeem = () => {
    setOnRedeem(true);
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={close}>
      <Box position="relative" zIndex={999999999}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="1.5rem">ACCESS RESTRICTED</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="1rem" pb="2rem">
              <Text fontSize="1.5rem">
                You need upgrade to{' '}
                <Text as="span" fontWeight="bold" color="bluesky.500">
                  Borjouis / Sultan
                </Text>{' '}
                to access this resource
              </Text>
              {onRedeem ? (
                <RedeemForm callback={close} />
              ) : (
                <>
                  <Button onClick={handleBuyTicket} size="lg">
                    Buy Ticket
                  </Button>
                  <Button onClick={handleRedeem} size="lg">
                    Redeem Ticket
                  </Button>
                </>
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Box>
    </Modal>
  );
};

export default RestrictionModal;
