import { Box, Text, Stack } from '@chakra-ui/react';
import Markdown from '_components/Markdown';
import OctagonImage from '_components/OctagonImage';
import Speaker from '_components/Speaker';
import { getSpeakersByVenueType_speakers } from '_queries/types/getSpeakersByVenueType';

export interface SpeakerInfoProps {
  speaker: getSpeakersByVenueType_speakers;
}
const SpeakerInfo: React.FC<SpeakerInfoProps> = ({ speaker }) => {
  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      minHeight={{ xs: 'auto', lg: '18rem' }}
      mt="30px">
      <Speaker
        item={speaker}
        w={{ xs: '100%', lg: '250px' }}
        h={{ xs: '150px', lg: '200px' }}
        display="flex"
        flexDirection={{ xs: 'row', lg: 'column' }}
      />
      <Stack
        direction="column"
        w="100%"
        ml={{ xs: '0', lg: '25px !important' }}>
        <Markdown
          textOverflow="ellipsis"
          textAlign="justify"
          overflow="hidden"
          fontSize="1rem"
          mt="0px !important">
          {speaker?.biography}
        </Markdown>
      </Stack>
    </Stack>
  );
};

export default SpeakerInfo;
