import { gql } from '@apollo/client';

export default gql`
  fragment UploadFileFragment on UploadFile {
    id
    name
    alternativeText
    caption
    width
    height
    hash
    url
    previewUrl
    provider
    provider_metadata
    size
    mime
  }
`;
