import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { user, userVariables } from '_queries/types/user';
import userQueryString from '_queries/user.query';

export const useUserLazyQuery = (
  options?: QueryHookOptions<user, userVariables>,
) => {
  return useLazyQuery<user, userVariables>(userQueryString, options);
};

export const useUserQuery = (
  options?: QueryHookOptions<user, userVariables>,
) => {
  return useQuery<user, userVariables>(userQueryString, options);
};
