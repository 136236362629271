import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import React from 'react';

export interface HexagonButtonProps extends ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const HexagonButton: React.FC<HexagonButtonProps> = forwardRef<
  HexagonButtonProps,
  any
>(({ children, colorScheme, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      display="flex"
      bg={`${colorScheme}.500`}
      _hover={{
        bg: `${colorScheme}.600`,
      }}
      _active={{
        bg: `${colorScheme}.400`,
      }}
      fontWeight="600"
      padding={{ xs: '0.75rem 2rem', lg: '1.5rem 3rem' }}
      mx={{ xs: '0', lg: '0.5rem' }}
      textTransform="uppercase"
      fontSize={{ xs: '0.75rem', lg: '1rem' }}
      textShadow="0 2px 10px rgba(47, 26, 111, .85)"
      borderRadius="full"
      {...props}>
      {children}
    </Button>
  );
});

HexagonButton.defaultProps = {
  colorScheme: 'bluesky',
};

export default HexagonButton;
