import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalProps,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import Heading from '_components/DecoratedHeading';
import Markdown from '_components/Markdown';
import SpeakerInfo from '_components/SpeakerInfo';
import { getSpeakersByVenueType_speakers } from '_queries/types/getSpeakersByVenueType';
import { format } from 'date-fns';

export interface InfoModalProps extends Omit<ModalProps, 'children'> {
  speaker: getSpeakersByVenueType_speakers;
}

const InfoModal = (props: InfoModalProps) => {
  const { onClose, isOpen, speaker } = props;
  const [setHeight, setHeightModal] = useState(false);
  const _isMounted = useRef(false);
  const events = speaker?.events;
  const eventInformation = events?.[0];

  const setScreenOrientation = () => {
    if (window.innerHeight <= 400) {
      setHeightModal(true);
    }
  };

  useEffect(() => {
    // Initialize Detect Screen
    !_isMounted.current && setScreenOrientation();

    _isMounted.current = true;

    window.addEventListener('resize', setScreenOrientation);

    return () => window.removeEventListener('resize', setScreenOrientation);
  }, []);

  const dateTime =
    eventInformation &&
    format(new Date(eventInformation?.start_date), 'EEEE, MMMM dd yyyy');
  const startEvent =
    eventInformation && format(new Date(eventInformation?.start_date), 'HH:mm');
  const endDateEvent =
    eventInformation && format(new Date(eventInformation?.end_date), 'HH:mm');
  const venueTitle = eventInformation?.venue?.title;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <Box position="relative" zIndex={999999999999}>
        <ModalOverlay />
        <ModalContent pt="20px" h={{ sm: setHeight ? "100%" : "auto" }} overflowY={{ sm: "auto" }}>
          <ModalHeader fontSize="1.5rem">Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SpeakerInfo speaker={speaker} />
            <Heading
              fontSize="1.35rem"
              mt="3.5rem"
              useBottomSeparator
              position="relative">
              {eventInformation?.venue.type}
            </Heading>
            <Box>
              <Text fontSize="1.25rem" fontWeight="bold">
                {eventInformation?.name}
              </Text>
              <Text fontSize="1rem">{dateTime}</Text>
              {/* <Text fontWeight="bold" fontSize="16px" mt="0.5rem">
              {venueTitle} - {startEvent} - {endDateEvent} GMT+7
            </Text> */}
              <Markdown
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize="1rem"
                mt="1rem">
                {eventInformation?.content}
              </Markdown>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Modal>
  );
};

export default InfoModal;
