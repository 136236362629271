import { useQuery } from '@apollo/client';
import notificationQuery from '_queries/notifications.query';
import {
  notificationQuery as notificationQueryType,
  notificationQueryVariables,
} from '_queries/types/notificationQuery';

const useNotificationsQuery = () => {
  const { data, ...rest } = useQuery<
    notificationQueryType,
    notificationQueryVariables
  >(notificationQuery, {});

  return {
    data: {
      notifications: data?.notifications ?? [],
    },
    ...rest,
  };
};

export default useNotificationsQuery;
