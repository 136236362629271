import { Metadata as UnleashMetadata } from '@kompasfest/f9g-adapter-unleash';
import { IFeatureToggle } from '@kompasfest/f9g-core';
import isEqual from 'lodash/isEqual';
import propTypes from 'prop-types';
import React from 'react';

interface IFeatureFlagStatus {
  isReady: boolean;
}

export interface IFeatureFlagContextValues<T> extends IFeatureFlagStatus {
  adapter?: IFeatureToggle<T>;
}

export interface ProviderProps<T> {
  adapter: IFeatureToggle<T>;
  children: React.ReactNode;
}

const FeatureFlagContext = React.createContext<
  IFeatureFlagContextValues<UnleashMetadata>
>({
  adapter: undefined,
  isReady: false,
});

const FeatureToggleProvider: React.FC<ProviderProps<UnleashMetadata>> = ({
  children,
  adapter,
}) => {
  const [flags, setFlags] = React.useState([]);
  const [isReady, setReady] = React.useState(false);
  const stateRef = React.useRef();
  stateRef.current = flags as any;

  const handleUpdate = (val) => {
    if (!isEqual(stateRef.current, val)) {
      setFlags(val);
    }
  };

  // initialize the client instance
  const initState = React.useCallback(async () => {
    adapter.start();
    adapter.ready().then(() => {
      if (!isReady) {
        setReady(true);
      }
    });
  }, [adapter]);

  React.useEffect(() => {
    if (isReady) {
      adapter.update(handleUpdate);
    }
  }, [isReady]);

  // call the init on load
  React.useEffect(() => {
    initState();

    return () => {
      adapter.stop();
    };
  }, [initState]);

  return (
    <FeatureFlagContext.Provider value={{ adapter, isReady }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

FeatureToggleProvider.propTypes = {
  adapter: propTypes.any,
};

const withFeatureToggle =
  (name: string) => (FallbackComponent: React.FC) => (Component: React.FC) => {
    const withFeatureToggle: React.FC<any> = (props) => {
      const _props = props ?? {};
      const { adapter } = React.useContext(FeatureFlagContext);

      if (!adapter) {
        return <FallbackComponent {..._props} />;
      }

      if (adapter.isEnabled(name)) {
        return <Component {..._props} />;
      }

      return <FallbackComponent {..._props} />;
    };

    return withFeatureToggle;
  };

const withFeatureToggleFn =
  (name: string) => (fallbackFn: any) => (fn: any) => (ctx) => {
    const withFeatureToggle = (...args) => {
      const { adapter } = ctx;

      if (!adapter) {
        return fallbackFn(args);
      }

      if (adapter.isEnabled(name)) {
        return fn(args);
      }

      return fallbackFn(args);
    };

    return withFeatureToggle;
  };

export {
  FeatureFlagContext,
  FeatureToggleProvider,
  withFeatureToggle,
  withFeatureToggleFn,
};
