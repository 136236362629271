import { ReactNode } from 'react';
import create from 'zustand';

type GenericDrawer = {
  title: string;
  content: string | ReactNode;
  action?: ReactNode;
};

interface IGenericDrawerStore {
  data?: GenericDrawer;
  isOpen: boolean;
  open: (data: GenericDrawer) => void;
  close: () => void;
}

export const useGenericDrawerStore = create<IGenericDrawerStore>((set) => ({
  data: null,
  isOpen: false,
  open: (data: GenericDrawer) => set(() => ({ isOpen: true, data })),
  close: () => set(() => ({ isOpen: false, data: null })),
}));
