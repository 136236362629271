import { Box } from '@chakra-ui/react';
import { useGLTF } from '@react-three/drei';
import GenericDrawer from '_components/GenericDrawer';
import GenericModal from '_components/GenericModal';
import InformationModal from '_components/InfoModal';
import PageLoader from '_components/PageLoader';
import RestrictionModal from '_components/RestrictionModal';
import { useAuth, usePingInterval, useTawk } from '_hooks';
import { useSpeakerModalStore } from '_store';
import { useCentralFetcher } from '_store/central';
import { Leva } from 'leva';
import _ from 'lodash';
import React from 'react';

const isProduction = process.env.NODE_ENV === 'production';

const RootPage: React.FC = (props) => {
  usePingInterval();
  useTawk();
  useCentralFetcher();
  useAuth();
  const {
    isOpen: openModalSpeaker,
    close: onCloseModalSpaker,
    speaker,
  } = useSpeakerModalStore();

  return (
    <>
      <Leva hidden={!!isProduction} />
      <InformationModal
        isOpen={openModalSpeaker}
        onClose={onCloseModalSpaker}
        // @ts-ignore
        speaker={speaker}
      />
      <GenericModal />
      <GenericDrawer />
      <RestrictionModal />
      <Box>{props.children}</Box>
    </>
  );
};

useGLTF.preload('/3d/entity/booth-platform.glb');
useGLTF.preload('/3d/entity/booth.glb');

export default RootPage;
