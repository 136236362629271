import { gql } from '@apollo/client';

export default gql`
  fragment NotificationFragment on Notifications {
    id
    content
    time_to_published
    venue {
      title
      slug
      type
      position
    }
  }
`;
