import { useCentralStore } from '_store/central';

export const isOngoingEvent = (start_date, end_date) => {
  const { now } = useCentralStore.getState();
  const startDate = new Date(start_date).getTime();
  const endDate = new Date(end_date).getTime();

  return startDate <= now && endDate >= now;
};

export const isUpcomingEvent = (start_date) => {
  const { now } = useCentralStore.getState();
  const startDate = new Date(start_date).getTime();

  return startDate >= now;
};

export const isPastEvent = (end_date) => {
  const { now } = useCentralStore.getState();
  const endDate = new Date(end_date).getTime();

  return endDate <= now;
};
