import create from 'zustand';

export interface IBoothStore {
  target?: THREE.Vector3;
  isSingleFocus: boolean;
  uuid?: string;
  schedule?: string;
  setSchedule: (id: string) => void;
  clearSchedule: () => void;
  setSingleFocus: (focus: boolean, uuid?: string) => void;
  setTarget: (position: THREE.Vector3) => void;
  clearTarget: () => void;
  reset: () => void;
}

export const useBoothStore = create<IBoothStore>((set) => ({
  target: null,
  isSingleFocus: false,
  uuid: null,
  schedule: null,
  clearSchedule: () => {
    set({
      schedule: null,
    });
  },
  setSchedule: (id: string) => {
    set({ schedule: id });
  },
  setSingleFocus: (focus: boolean, uuid?: string) => {
    set((state) => {
      state.isSingleFocus = focus;
      state.uuid = uuid;
    });
  },
  setTarget: (position: THREE.Vector3) => {
    set((state: any) => {
      state.target = position;
    });
  },
  clearTarget: () => {
    set((state: any) => {
      state.target = null;
    });
  },
  reset: () => {
    set(() => ({
      target: null,
      isSingleFocus: false,
      uuid: null,
      schedule: null,
    }));
  },
}));
