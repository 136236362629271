import { Stack, StackProps, Text } from '@chakra-ui/react';
import HardSellingSultan from '_components/HardSellingSultan';
import { useAccessControl } from '_hooks/useAccessControl';

interface EmptyStateEvent extends StackProps {}

const EmptyStateEvent: React.FC<EmptyStateEvent> = ({ ...props }) => {
  const { userLevel } = useAccessControl();

  return (
    <Stack display="flex" alignItems="center" {...props}>
      <Text>Sorry spacemate, you've missed our awesome events.</Text>
      <HardSellingSultan userLevel={userLevel} />
    </Stack>
  );
};

export default EmptyStateEvent;
