import {
  Stack,
  Image,
  BoxProps,
  Text,
  Heading,
  AvatarGroup,
  Avatar,
  Button,
} from '@chakra-ui/react';
import CountdownRenderer from '_components/CountdownRenderer';
import { events_eventsConnection_values } from '_queries/types/events';
import { getRelativeNow } from '_store/central';
import { resolveImage } from '_utils';
import { isOngoingEvent, isPastEvent } from '_utils/event';
import { format } from 'date-fns';
import React from 'react';
import Countdown from 'react-countdown';

export interface EventCardProps extends BoxProps {
  item: events_eventsConnection_values;
  started?: boolean;
  onCheckin?: (eventId: string) => void;
}

const EventCard: React.FC<EventCardProps> = (props) => {
  const { item } = props;

  const thumbnail = item?.assetCollections?.find(
    (item) => item.refId === 'thumbnail',
  );
  const speakers = item?.speakers ?? [];

  const dateTime =
    item?.start_date && format(new Date(item.start_date), 'EEEE, MMMM dd yyyy');
  const startEvent =
    item?.start_date && format(new Date(item.start_date), 'HH:mm');
  const endDateEvent =
    item?.end_date && format(new Date(item.end_date), 'HH:mm');

  return (
    <Stack spacing="1.2rem" direction="row">
      <Image
        bg="white"
        width="130px"
        height="130px"
        objectFit="cover"
        borderRadius="lg"
        src={resolveImage(thumbnail?.source?.url) ?? '/kompas-thumbnail.svg'}
      />
      <Stack spacing="0.5rem" pr="1rem">
        <Stack direction="column">
          <Text fontWeight="600">{dateTime}</Text>
          <Text>
            {startEvent} - {endDateEvent} GMT+7
          </Text>
        </Stack>
        <Heading size="md">{item?.name}</Heading>
        <AvatarGroup size="sm" max={3}>
          {speakers.map((speaker) => (
            <Avatar
              key={speaker.id}
              src={resolveImage(speaker.profile_picture?.url)}
              name={speaker.name}
            />
          ))}
        </AvatarGroup>
        <Stack direction="row" spacing="1rem">
          <Button
            minWidth="21rem"
            onClick={() => props.onCheckin && props.onCheckin(item?.id)}
            colorScheme={
              isOngoingEvent(item.start_date, item.end_date) ? 'bluesky' : null
            }>
            {props.started ? (
              <Stack direction="row">
                <Text>CHECK IN NOW! Event Ends In{` `}</Text>
                <Countdown
                  now={getRelativeNow}
                  renderer={CountdownRenderer}
                  date={new Date(item?.end_date).getTime()}
                />
              </Stack>
            ) : !isPastEvent(item.end_date) ? (
              <Stack direction="row">
                <Text>Event Starts In{` `}</Text>
                <Countdown
                  now={getRelativeNow}
                  renderer={CountdownRenderer}
                  date={new Date(item?.start_date)}
                />
              </Stack>
            ) : (
              <Stack direction="row">
                <Text>Information</Text>
              </Stack>
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventCard;
