import React from 'react';

export type AccessControlProviderType = {
  userLevel: number;
  setUserLevel: (level: number) => void;
};

export const AccessControlContext =
  React.createContext<AccessControlProviderType>(null);

export const AccessControlProvider: React.FC = (props) => {
  const [state, setState] = React.useState({
    userLevel: -1,
  });

  const setUserLevel = React.useCallback((level: number) => {
    setState((prev) => ({ ...prev, userLevel: level }));
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...state,
      setUserLevel,
    };
  }, [state]);

  return (
    <AccessControlContext.Provider value={contextValue}>
      {props.children}
    </AccessControlContext.Provider>
  );
};
