import { gql } from '@apollo/client';
import UploadFileFragment from '_queries/upload-file.fragment'

export default gql`
  fragment AssetCollectionFragment on ComponentCollectionsAsset {
    id
    refId
    source {
      ...UploadFileFragment
    }
  }
  ${UploadFileFragment}
`;
